import React, { Component } from 'react';
import {
  Button,
  Grid,
  TextField,
  IconButton,
  Divider,
  InputLabel,
  Select,
  Card,
  MenuItem,
  CardHeader,
  CardContent,
  ListItemSecondaryAction,
  Badge,
} from '@material-ui/core';
import Store from '../../services/Store';
import axios from '../../helpers/axios';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import CssBaseline from '@material-ui/core/CssBaseline';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { storage } from '../../services/firebase';
import PhotoModal from '../../components/PhotoModal';
import ConfirmDialog from '../../components/ConfirmDialog';
import Resizer from 'react-image-file-resizer';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Tooltip from '@material-ui/core/Tooltip';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={'simple-tabpanel-${index}'}
      aria-labelledby={'simple-tab-${index}'}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '15px',
    width: '15px',
    left: '15px',
    top: '15px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class ResolveReports extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      uiLoading: true,
      modalOpen: false,
      code: {},
      tabValue: 0,
      modalTypeOpen: false,
      newSelectRubbishType: '',
      teamDisplayName: '',
      teamMembers: [],
      newTeamMemberID: '',
      reports: [],
      confirmOpen: false,
      confirmModalTitle: '',
      photoPreviewAfter: 'Attach Photo',
    };
  }
  componentDidMount = () => {
    this._isMounted = true;
    const { store, codeID } = this.props;
    const authToken = store.get('user').accessToken;
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(`/api/codes/${codeID}/open_reports`)
      .then((response) => {
        if (this._isMounted) {
          this.setState({
            reports: response.data.reports,
            uiLoading: false,
            modalOpen: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({
            buttonLoading: false,
          });
        }
      });
    this.setState({ modalOpen: true });
  };
  componentWillUnmount() {
    if (this._isMounted) this._isMounted = false;
  }
  setConfirmOpen = (setConfirm = false) => {
    this.setState({
      confirmOpen: setConfirm,
      confirmModalTitle: '',
      confirmationFunction: undefined,
    });
  };
  handleOpenType = () => {
    this.setState({ modalTypeOpen: true });
  };
  handleCloseType = () => {
    this.setState({ modalTypeOpen: false });
  };
  handleClose = () => {
    this.props.handleEditClose();
  };
  handleToastClose = () => {
    this.setState({ toastOpen: false });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleCheck = (event) => {
    if (event.target.checked) {
      this.setState({ [event.target.name]: true });
    } else {
      this.setState({ [event.target.name]: false });
    }
  };
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };
  onSelectType = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  formatToPhone = (event) => {
    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      target.value = `(${zip}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      target.value = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      target.value = `(${zip}`;
    }
    this.setState({ [event.target.name]: event.target.value });
  };
  addZero = (i) => {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  };
  timeConverter = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = this.addZero(a.getHours());
    var ampm = hour >= 12 ? 'pm' : 'am';
    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'
    var min = this.addZero(a.getMinutes());
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ampm;
    return time;
  };

  resolveReport = (reportId) => {
    const { store, codeID } = this.props;
    const { reports } = this.state;

    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');

    axios.defaults.headers.common = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${authToken}`,
    };

    var formData = new FormData();

    formData.append('reportIDs', [reportId]);

    if (this.state.photoStringAfter) {
      formData.append('rubbishAfterPhoto', this.state.photoStringAfter);
    }
    this.setState({ showQuickLoad: true });
    window.this = this;
    axios
      .post(`/api/code/${codeID}/resolve_reports`, formData)
      .then((teamResponse) => {
        let newReports = reports.filter((item) => item.id !== reportId);
        window.this.setState({
          reports: newReports,
          photoStringAfter: '',
          photoPreviewAfter: 'Attach an After Photo',
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
  };

  resolveAllReports = () => {
    const { store, codeID } = this.props;
    const { reports } = this.state;

    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');

    axios.defaults.headers.common = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${authToken}`,
    };
    let reportIds = [];
    reports.forEach((r) => {
      reportIds.push(r.id);
    });
    var formData = new FormData();

    formData.append('reportIDs', reportIds);

    if (this.state.photoStringAfter) {
      formData.append('rubbishAfterPhoto', this.state.photoStringAfter);
    }
    this.setState({ showQuickLoad: true });
    window.this = this;
    axios
      .post(`/api/code/${codeID}/resolve_reports`, formData)
      .then((teamResponse) => {
        window.this.setState({
          reports: [],
          photoStringAfter: '',
          photoPreviewAfter: 'Attach an After Photo',
        });
        this.props.handleEditClose();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
  };
  triggerPhotoAfter = (e) => {
    e.preventDefault();
    document.getElementById('rubbishAfterPhoto').click();
  };
  showPhotoPreviewAfter = (e) => {
    const { photoPreviewAfter } = this.state;
    const file = document.querySelector('#rubbishAfterPhoto').files[0];
    if (file) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          500,
          500,
          'JPEG',
          100,
          0,
          (uri) => {
            let photoPreviewAfter = <img className="photo-preview" src={uri} />;
            this.setState({ photoPreviewAfter: photoPreviewAfter });
          },
          'base64',
          200,
          200,
        );
        Resizer.imageFileResizer(
          e.target.files[0],
          500,
          500,
          'JPEG',
          100,
          0,
          (uri) => {
            this.setState({ photoStringAfter: uri });
          },
          'file',
          200,
          200,
        );
      } catch (err) {
        console.log(err);
        this.setState({
          photoPreviewAfter: 'Error Attaching Photo',
          photoStringAfter: null,
        });
      }
    }
  };
  render() {
    const { codeID, classes } = this.props;
    const { modalOpen, code, tabValue, modalTypeOpen } = this.state;

    let modalStyle = {
      top: `45%`,
      left: `50%`,
      transform: `translate(-45%, -50%)`,
    };
    let primaryImage = null;
    if (code.artGallery === undefined) {
      code.artGallery = [];
    }
    if (code.artImageURL !== undefined) {
      primaryImage = (
        <img className="img-thumbnail" alt="primary" src={code.artImageURL} />
      );
    }

    if (this.state.uiLoading === true) {
      return (
        <>
          <Dialog
            fullWidth
            maxWidth="md"
            open={true}
            onClose={this.handleClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogContent>
              {this.state.uiLoading && (
                <CircularProgress size={150} className={classes.uiProgess} />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    } else {
      let codeRubbishTypeIDS = [];
      if (code.rubbishTypeIDS) {
        codeRubbishTypeIDS = code.rubbishTypeIDS;
      }
      return (
        <>
          <Dialog
            fullWidth
            maxWidth="md"
            open={modalOpen}
            onClose={this.handleClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle className={classes.root} id="max-width-dialog-title">
              <CssBaseline />
              <div className={classes.root}>
                <Box width="100%">
                  Open Reports at {this.props.codeIdentifier}
                </Box>
              </div>
            </DialogTitle>
            <DialogContent>
              <div>
                <List>
                  {Object.keys(this.state.reports).map((key) => {
                    let r = this.state.reports[key];
                    let rubbishPhoto = <DeleteOutlineIcon />;
                    if (r.rubbishPhotoURL) {
                      rubbishPhoto = (
                        <PhotoModal rubbishPhotoURL={r.rubbishPhotoURL} />
                      );
                    }
                    return (
                      <div key={key}>
                        <ListItem key={key}>
                          <ListItemAvatar>
                            <Avatar
                              variant="rounded"
                              className={classes.rounded}
                            >
                              {rubbishPhoto}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={r.comments}
                            secondary={
                              r.rubbishType +
                              ' :: Reported at ' +
                              this.timeConverter(r.reportedAt)
                            }
                          />
                          <ListItemSecondaryAction>
                            <Tooltip title="Resolve">
                              <IconButton
                                onClick={() => {
                                  this.setState({
                                    confirmationFunction: () => {
                                      this.resolveReport(r.id);
                                    },
                                    confirmModalTitle:
                                      'Mark Report as Resolved?',
                                    confirmOpen: true,
                                  });
                                }}
                                edge="end"
                                aria-label="delete"
                              >
                                <DoneOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </div>
                    );
                  })}
                </List>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    confirmationFunction: () => {
                      this.resolveAllReports();
                    },
                    confirmModalTitle: 'Resolve All Reports?',
                    confirmOpen: true,
                  });
                }}
                color="primary"
                variant="contained"
              >
                Resolve All
              </Button>
              <Button onClick={this.handleClose} variant="contained">
                Close
              </Button>
            </DialogActions>

            <ConfirmDialog
              title={this.state.confirmModalTitle}
              open={this.state.confirmOpen}
              setOpen={this.setConfirmOpen}
              onConfirm={() => this.state.confirmationFunction()}
            >
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <Button
                  className={classes.button}
                  fullWidth
                  variant="outlined"
                  size="large"
                  endIcon={<CameraAltIcon />}
                  onClick={this.triggerPhotoAfter}
                >
                  {this.state.photoPreviewAfter}
                </Button>
                <input
                  onChange={this.showPhotoPreviewAfter}
                  id="rubbishAfterPhoto"
                  name="rubbishAfterPhoto"
                  type="file"
                  accept="image/*"
                  capture="camera"
                />
              </FormControl>
            </ConfirmDialog>
          </Dialog>
        </>
      );
    }
  }
}

export default Store.withStore(withStyles(styles)(ResolveReports));
