import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { auth } from '../services/firebase';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import NotesIcon from '@material-ui/icons/Notes';
import GroupIcon from '@material-ui/icons/Group';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Avatar from '@material-ui/core/Avatar';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { TextField, MenuItem, IconButton } from '@material-ui/core';
import axios from '../helpers/axios';
import AddIcon from '@material-ui/icons/Add';
import Store from '../services/Store';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InputBase from '@material-ui/core/InputBase';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import BusinessIcon from '@material-ui/icons/Business';

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  cleanerButton: {
    flex: 2,
    '& span': {
      justifyContent: 'right',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'var(--secondary-bg-color)',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  avatar: {
    height: 110,
    width: 110,
    flexShrink: 0,
    flexGrow: 0,
    marginTop: 20,
    marginBottom: 20,
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '45%',
    top: '35%',
  },
  appLogo: {
    fontFamily: 'SF UI Text Bold',
    fontSize: 24,
    maxWidth: '100%',
    padding: 15,
  },
  toolbar: theme.mixins.toolbar,
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
//     },
//   },
// };
const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#F10382',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

class Header extends Component {
  constructor(props) {
    super(props);
    const { store } = this.props;
    this.state = {
      uiLoading: true,
      imageLoading: false,
      currentTeam: store.get('currentTeam') || '0',
      modalOpen: false,
      newTeamName: '',
      mobileOpen: false,
      photoURL: store.get('user').photoURL,
    };
    // console.log(store.get('user').photoURL);
    // this.toDataUrl(store.get('user').photoURL, (myBase) => {
    //   this.setState({profilePicture: myBase});
    // });
  }
  toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  componentDidMount = () => {
    const { store } = this.props;
    store.on('user').subscribe((user) => {
      // console.log
      this.setState({ photoURL: user.photoURL });
    });
    store.on('currentTeam').subscribe((team) => {
      // console.log
      this.setState({ currentTeam: team });
    });
  };
  changeTeam = (event) => {
    const { store } = this.props;
    store.set('currentTeam')(event.target.value);
    this.setState({ currentTeam: event.target.value });
  };
  handleOpen = () => {
    this.setState({ modalOpen: true, newTeamName: '' });
  };
  handleClose = () => {
    this.setState({ modalOpen: false });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  createTeam = () => {
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    const formRequest = {
      teamDisplayName: this.state.newTeamName,
    };
    axios
      .post('/api/team', formRequest)
      .then((teamResponse) => {
        axios.defaults.headers.common = {
          Authorization: `Bearer ${authToken}`,
        };
        axios
          .get('/api/user')
          .then((response) => {
            // store.set('user')({
            //   accessToken: authToken,
            //   authenticated: true,
            //   firstName: response.data.userCredentials.firstName,
            //   lastName: response.data.userCredentials.lastName,
            //   myTeams: response.data.teams,
            //   moderator: response.data.moderator
            // });
            store.set('user')({
              accessToken: authToken,
              authenticated: true,
              firstName: response.data.userCredentials.firstName,
              lastName: response.data.userCredentials.lastName,
              userRole: response.data.userCredentials.userRole,
              myTeams: response.data.teams,
              photoURL: response.data.userCredentials.photoURL,
              email: response.data.email,
              moderator: response.data.moderator,
            });
            store.set('currentTeam')(teamResponse.data.id);
            this.setState({ currentTeam: teamResponse.data.id });
          })
          .catch((error) => {
            console.log(error);
            window.localStorage.clear();
            this.setState({
              authenticated: false,
              loading: false,
            });
          });
        //add team to tam list and set it as current team
        this.setState({ buttonLoading: false });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.props.history.push('/login');
        }
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
    this.handleClose();
  };

  render() {
    const { classes, window } = this.props;
    const { store } = this.props;
    const { currentTeam, modalOpen } = this.state;
    let navigation = '';
    let teamText = 'My Team';
    let modalStyle = {
      top: `45%`,
      left: `50%`,
      transform: `translate(-45%, -50%)`,
    };
    let addTeamButton = '';
    let user = store.get('user');
    let teamSlugID = undefined;
    if (currentTeam.length > 0 && user.myTeams[currentTeam]) {
      teamSlugID = user.myTeams[currentTeam].teamSlugID;
    }
    let userRole = user.userRole;
    let superMenu = '';
    if (userRole == 'admin') {
      addTeamButton = (
        <IconButton
          size="small"
          style={{ width: 30 }}
          onClick={this.handleOpen}
          aria-label="settings"
        >
          <AddCircleIcon fontSize="medium" color="primary" />
        </IconButton>
      );
      // superMenu = (
      //   <>
      //     <Divider />
      //       <ListItem button key="Dashboard" component={NavLink} to="/dashboard" activeClassName="active">
      //         <ListItemIcon>
      //           {' '}
      //           <BusinessIcon />{' '}
      //         </ListItemIcon>
      //         <ListItemText primary="All Teams" />
      //       </ListItem>
      //   </>
      // );
    }
    if (store.get('currentTeam') !== '0') {
      if (store.get('user').moderator.includes(store.get('currentTeam'))) {
        teamText = 'Manage Team';
      }
      navigation = (
        <>
          <ListItem>
            {teamSlugID != undefined ? (
              <Button
                target="_blank"
                href={'/t/' + teamSlugID + '?log=true'}
                variant="outlined"
                color="primary"
                fullWidth
              >
                Report Issue
              </Button>
            ) : (
              ''
            )}
          </ListItem>
          <ListItem
            button
            key="Dashboard"
            component={NavLink}
            to="/dashboard"
            activeClassName="active"
          >
            <ListItemIcon>
              {' '}
              <DashboardIcon />{' '}
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>

          <ListItem
            button
            key="Reports"
            component={NavLink}
            to="/reports"
            activeClassName="active"
          >
            <ListItemIcon>
              {' '}
              <AssessmentIcon />{' '}
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to="/qrcodes"
            activeClassName="active"
            key="Links & Codes"
          >
            <ListItemIcon>
              {' '}
              <CropFreeIcon />{' '}
            </ListItemIcon>
            <ListItemText primary="QR Codes & Links" />
          </ListItem>

          <ListItem
            button
            key="Manage Team"
            component={NavLink}
            to="/team"
            activeClassName="active"
          >
            <ListItemIcon>
              {' '}
              <GroupIcon />{' '}
            </ListItemIcon>
            <ListItemText primary={teamText} />
          </ListItem>
        </>
      );
    }
    const drawer = (
      <div>
        <img
          className={classes.appLogo}
          src="/images/rubbish_logotype_rgb_c.png"
        />
        <center>
          <Avatar src={this.state.photoURL} className={classes.avatar} />
          <p style={{ fontSize: 18 }}>
            {' '}
            {store.get('user').firstName} {store.get('user').lastName}
          </p>
        </center>

        <div
          style={{
            paddingBottom: 10,
            paddingLeft: 5,
            paddingRight: 5,
            display: 'flex',
          }}
        >
          <Select
            input={<BootstrapInput />}
            value={currentTeam}
            style={{ flex: 1, backgroundColor: '#fff', overflow: 'hidden' }}
            MenuProps={MenuProps}
            onChange={this.changeTeam}
          >
            <MenuItem key={0} value="0" disabled>
              Select Team
            </MenuItem>
            {Object.keys(store.get('user').myTeams).map((key) => {
              return (
                <MenuItem
                  key={key}
                  value={store.get('user').myTeams[key]['teamId']}
                >
                  {store.get('user').myTeams[key]['teamDisplayName']}
                </MenuItem>
              );
            })}
          </Select>

          {addTeamButton}
        </div>

        <Divider />
        <List>
          {navigation}

          <ListItem
            button
            key="My Account"
            component={NavLink}
            to="/account"
            activeClassName="active"
          >
            <ListItemIcon>
              {' '}
              <AccountBoxIcon />{' '}
            </ListItemIcon>
            <ListItemText primary="My Account" />
          </ListItem>

          <ListItem button key="Logout" onClick={() => auth().signOut()}>
            <ListItemIcon>
              {' '}
              <ExitToAppIcon />{' '}
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>

        {superMenu}
      </div>
    );

    const container =
      window !== undefined ? () => window().document.body : undefined;
    return (
      <header>
        <CssBaseline />
        <Hidden smUp implementation="css">
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography className={classes.appLogo} noWrap>
                rubbish
              </Typography>
              {teamSlugID != undefined ? (
                <IconButton
                  color="inherit"
                  aria-label="cleaner view"
                  edge="start"
                  href={'/t/' + teamSlugID + '?log=true'}
                  className={classes.cleanerButton}
                >
                  <CleaningServicesIcon />
                </IconButton>
              ) : (
                ''
              )}
            </Toolbar>
          </AppBar>
        </Hidden>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>

        <Modal
          open={modalOpen}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">Create New Teamm</h2>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  label="Team Name"
                  margin="dense"
                  name="newTeamName"
                  variant="outlined"
                  value={this.state.newTeamName}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={this.createTeam}
                  variant="contained"
                  color="primary"
                >
                  +
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </header>
    );
  }
}
export default Store.withStore(withStyles(styles)(Header));
