import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  signup,
  signInWithGoogle,
  signInWithFacebook,
  signInWithApple,
} from '../helpers/auth';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormGroup,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import axios from '../helpers/axios';
import Grid from '@material-ui/core/Grid';
import Store from '../services/Store';
import withStyles from '@material-ui/core/styles/withStyles';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" to="https://material-ui.com/">
        Rubbish.love
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = (theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/images/background.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imageTag: {
    margin: 15,
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignUp extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      loading: true,
      inactive: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleNewUser = this.handleNewUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.googleSignIn = this.googleSignIn.bind(this);
    this.facebookSignIn = this.facebookSignIn.bind(this);
    this.appleSignIn = this.appleSignIn.bind(this);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const inviteCode = urlParams.get('code');
    const inviteHash = urlParams.get('invite');
    axios
      .get(`/api/invites/${inviteCode}/code/${inviteHash}`)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.inviteStatus === 'PENDING'
        ) {
          this.setState({
            loading: false,
            inactive: false,
            email: response.data.emailAddress,
          });
        } else {
          this.setState({ loading: false, inactive: true });
        }
      });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async handleNewUser(result) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const inviteCode = urlParams.get('code');
    const inviteHash = urlParams.get('invite');

    // var credential = result.credential;
    // const token = credential.accessToken;
    result.user.getIdToken().then((accessToken) => {
      const data = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      };
      localStorage.setItem('token', accessToken);
      axios
        .post(`/api/invites/${inviteCode}/accept/${inviteHash}`, data)
        .then((response) => {
          console.log(response);
          // redirect to root url
          // window.location.href = '/';
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });
    try {
      await signup(this.state.email, this.state.password).then((result) => {
        this.handleNewUser(result);
      });
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  async googleSignIn() {
    try {
      await signInWithGoogle().then((result) => {
        this.handleNewUser(result);
      });
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  async facebookSignIn() {
    try {
      await signInWithFacebook().then((result) => {
        this.handleNewUser(result);
      });
    } catch (error) {
      console.log(error);
      this.setState({ error: error.message });
    }
  }
  async appleSignIn() {
    try {
      await signInWithApple().then((result) => {
        this.handleNewUser(result);
      });
    } catch (error) {
      console.log(error);
      this.setState({ error: error.message });
    }
  }

  render() {
    const { classes } = this.props;
    if (this.state.loading) return <div className="container">Loading</div>;

    if (this.state.inactive) {
      return (
        <div className="container">
          <h1>Invite is no longer active</h1>
        </div>
      );
    }

    return (
      <>
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image}>
            <img className={classes.imageTag} src="/images/logo.png" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <NewReleasesIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign Up
              </Typography>
              {this.state.error}
              <form
                className={classes.form}
                noValidate
                onSubmit={this.handleSubmit}
              >
                <p className="lead">
                  Fill in the form below to create an account.
                </p>
                <div className="form-group">
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    readOnly={true}
                    autoComplete="email"
                    value={this.state.email}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    onChange={this.handleChange}
                    autoComplete="firstName"
                    value={this.state.firstName}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    onChange={this.handleChange}
                    autoComplete="lastName"
                    value={this.state.lastName}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    onChange={this.handleChange}
                    autoComplete="current-password"
                    value={this.state.password}
                  />
                </div>

                <div className="form-group">
                  {this.state.error ? (
                    <p className="text-danger">{this.state.error}</p>
                  ) : null}
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="primary"
                        className="mb-2"
                      >
                        Sign Up
                      </Button>
                    </Grid>
                  </Grid>
                </div>

                <p>You can also sign up with any of these services</p>
                <Button
                  variant="outlined"
                  type="button"
                  fullWidth
                  className="mb-2"
                  onClick={this.googleSignIn}
                >
                  Sign up with Google
                </Button>
                <Button
                  variant="outlined"
                  type="button"
                  fullWidth
                  style={{ backgroundColor: '#3b5998', color: '#ffffff' }}
                  className="mb-2"
                  onClick={this.facebookSignIn}
                >
                  Sign up with Facebook
                </Button>
                <Button
                  variant="outlined"
                  type="button"
                  fullWidth
                  style={{ backgroundColor: '#000000', color: '#ffffff' }}
                  className="mb-2"
                  onClick={this.appleSignIn}
                >
                  Sign up with Apple
                </Button>
                <hr></hr>
                <p>
                  Already have an account? <Link to="/login">Login</Link>
                </p>

                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default Store.withStore(withStyles(styles)(SignUp));
