import React, { Component } from 'react';
import { linkGoogleAccount, linkGithubAccount } from '../helpers/auth';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import Header from '../components/Header';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Footer from '../components/Footer';
import clsx from 'clsx';

import axios from '../helpers/axios';
import Store from '../services/Store';

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: '15px',
  },
  buttonProperty: {
    position: 'absolute',
    top: '50%',
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    marginLeft: -75,
    left: '50%',
    top: '35%',
  },
  progess: {
    position: 'absolute',
  },
  uploadButton: {
    marginLeft: '8px',
    margin: theme.spacing(1),
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10,
  },
  submitButton: {},
});

class Home extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    const { store } = this.props;

    this.state = {
      firstName: '',
      lastName: '',
      email: store.get('user').email || '',
      phoneNumber: '',
      userName: '',
      userBio: '',
      profilePicture: '',
      uiLoading: true,
      buttonLoading: false,
      imageError: '',
      image: '',
    };
  }

  componentWillMount = () => {
    this._isMounted = true;
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get('/api/user')
      .then((response) => {
        if (this._isMounted) {
          let notifications = {};
          if (response.data.notificationTimes) {
            if (response.data.notificationTimes.monday) {
              notifications['mondayActive'] =
                response.data.notificationTimes.monday.active || false;
              notifications['mondayStart'] =
                response.data.notificationTimes.monday.startTime || '';
              notifications['mondayEnd'] =
                response.data.notificationTimes.monday.endTime || '';
            }
            if (response.data.notificationTimes.tuesday) {
              notifications['tuesdayActive'] =
                response.data.notificationTimes.tuesday.active || false;
              notifications['tuesdayStart'] =
                response.data.notificationTimes.tuesday.startTime || '';
              notifications['tuesdayEnd'] =
                response.data.notificationTimes.tuesday.endTime || '';
            }
            if (response.data.notificationTimes.wednesday) {
              notifications['wednesdayActive'] =
                response.data.notificationTimes.wednesday.active || false;
              notifications['wednesdayStart'] =
                response.data.notificationTimes.wednesday.startTime || '';
              notifications['wednesdayEnd'] =
                response.data.notificationTimes.wednesday.endTime || '';
            }
            if (response.data.notificationTimes.thursday) {
              notifications['thursdayActive'] =
                response.data.notificationTimes.thursday.active || false;
              notifications['thursdayStart'] =
                response.data.notificationTimes.thursday.startTime || '';
              notifications['thursdayEnd'] =
                response.data.notificationTimes.thursday.endTime || '';
            }
            if (response.data.notificationTimes.friday) {
              notifications['fridayActive'] =
                response.data.notificationTimes.friday.active || false;
              notifications['fridayStart'] =
                response.data.notificationTimes.friday.startTime || '';
              notifications['fridayEnd'] =
                response.data.notificationTimes.friday.endTime || '';
            }
            if (response.data.notificationTimes.saturday) {
              notifications['saturdayActive'] =
                response.data.notificationTimes.saturday.active || false;
              notifications['saturdayStart'] =
                response.data.notificationTimes.saturday.startTime || '';
              notifications['saturdayEnd'] =
                response.data.notificationTimes.saturday.endTime || '';
            }
            if (response.data.notificationTimes.sunday) {
              notifications['sundayActive'] =
                response.data.notificationTimes.sunday.active || false;
              notifications['sundayStart'] =
                response.data.notificationTimes.sunday.startTime || '';
              notifications['sundayEnd'] =
                response.data.notificationTimes.sunday.endTime || '';
            }
          }
          this.setState(notifications);
          this.setState({
            firstName: response.data.userCredentials.firstName,
            lastName: response.data.userCredentials.lastName,
            phoneNumber: response.data.phoneNumber,
            userBio: response.data.userCredentials.userBio,
            userName: response.data.userCredentials.userName || '',
            emailNotificationsActive: response.data.emailNotificationsActive,
            textNotificationsActive: response.data.textNotificationsActive,
            uiLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({ errorMsg: 'Error in retrieving the data' });
        }
      });
  };
  componentWillUnmount() {
    if (this._isMounted) this._isMounted = false;
  }
  formatToPhone = (event) => {
    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      target.value = `(${zip}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      target.value = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      target.value = `(${zip}`;
    }
    this.setState({ [event.target.name]: target.value });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleCheck = (event) => {
    if (event.target.checked) {
      this.setState({ [event.target.name]: true });
    } else {
      this.setState({ [event.target.name]: false });
    }
  };
  handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    this.setState(
      {
        image: selectedImage,
      },
      () => {
        // after selecting an image, trigger the profile picture update
        this.profilePictureHandler(event);
      },
    );
  };

  profilePictureHandler = (event) => {
    event.preventDefault();
    this.setState({
      uiLoading: true,
    });
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    let form_data = new FormData();
    console.log('image:', this.state.image);
    form_data.append('image', this.state.image);
    form_data.append('content', this.state.content);
    const form_data_img = form_data.get('image');
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .post('/api/user/image', form_data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        let user = store.get('user');
        user.photoURL = response.data.photoURL;
        // console.log(user);

        store.set('user')(user);
        this.setState({ uiLoading: false });
        // window.location.reload();
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.props.history.push('/login');
        }
        console.log(error);
        this.setState({
          uiLoading: false,
          imageError: 'Error in posting the data',
        });
      });
  };

  updateFormValues = (event) => {
    event.preventDefault();
    this.setState({ buttonLoading: true });
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    // console.log('UPDATE USERRRRRR');
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    const formRequest = {
      user: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        userBio: this.state.userBio,
        userName: this.state.userName.toLowerCase(),
      },
      privateData: {
        textNotificationsActive: this.state.textNotificationsActive,
        emailNotificationsActive: this.state.emailNotificationsActive,
        phoneNumber: this.state.phoneNumber,
        notificationTimes: {
          monday: {
            active: this.state.mondayActive,
            startTime: this.state.mondayStart,
            endTime: this.state.mondayEnd,
          },
          tuesday: {
            active: this.state.tuesdayActive,
            startTime: this.state.tuesdayStart,
            endTime: this.state.tuesdayEnd,
          },
          wednesday: {
            active: this.state.wednesdayActive,
            startTime: this.state.wednesdayStart,
            endTime: this.state.wednesdayEnd,
          },
          thursday: {
            active: this.state.thursdayActive,
            startTime: this.state.thursdayStart,
            endTime: this.state.thursdayEnd,
          },
          friday: {
            active: this.state.fridayActive,
            startTime: this.state.fridayStart,
            endTime: this.state.fridayEnd,
          },
          saturday: {
            active: this.state.saturdayActive,
            startTime: this.state.saturdayStart,
            endTime: this.state.saturdayEnd,
          },
          sunday: {
            active: this.state.sundayActive,
            startTime: this.state.sundayStart,
            endTime: this.state.sundayEnd,
          },
        },
      },
    };
    axios
      .post('/api/user', formRequest)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error);
        }
        this.setState({ buttonLoading: false });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.props.history.push('/login');
        }
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
  };

  render() {
    const { classes, staticContext, ...rest } = this.props;
    if (this.state.uiLoading === true) {
      return (
        <div className={classes.root}>
          <Header></Header>

          <main className={classes.content}>
            <div className={classes.toolbar} />
            {this.state.uiLoading && (
              <CircularProgress size={150} className={classes.uiProgess} />
            )}
          </main>
        </div>
      );
    } else {
      return (
        <div className={classes.root}>
          <Header></Header>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Card className={clsx(classes.root, classes)}>
              <CardContent>
                <div className={classes.details}>
                  <div>
                    <Typography
                      className={classes.locationText}
                      gutterBottom
                      variant="h4"
                    >
                      {this.state.firstName} {this.state.lastName}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="button" // change to button to prevent form submission
                      size="small"
                      startIcon={<CloudUploadIcon />}
                      className={classes.uploadButton}
                      onClick={() => this.fileInput.click()}
                    >
                      UPDATE PROFILE PHOTO
                    </Button>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      onChange={this.handleImageChange}
                      ref={(fileInput) => (this.fileInput = fileInput)}
                    />

                    {this.state.imageError ? (
                      <div className={classes.customError}>
                        {' '}
                        Wrong Image Format || Supported Format are PNG and JPG
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                </div>
                <div className={classes.progress} />
              </CardContent>
              <Divider />
            </Card>

            <br />
            <Card {...rest} className={clsx(classes.root, classes)}>
              <form autoComplete="off" noValidate>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="First name"
                        margin="dense"
                        name="firstName"
                        variant="outlined"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Last name"
                        margin="dense"
                        name="lastName"
                        variant="outlined"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        margin="dense"
                        name="email"
                        variant="outlined"
                        disabled={true}
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        margin="dense"
                        name="phoneNumber"
                        type="tel"
                        variant="outlined"
                        defaultValue={this.state.phoneNumber}
                        onChange={this.formatToPhone}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="User Name"
                        margin="dense"
                        name="userName"
                        variant="outlined"
                        value={this.state.userName}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        minRows={2}
                        label="User Bio"
                        margin="dense"
                        name="userBio"
                        variant="outlined"
                        value={this.state.userBio}
                        onChange={this.handleChange}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions />
              </form>
            </Card>
            <br />
            <Card {...rest} className={clsx(classes.root, classes)}>
              <form autoComplete="off" noValidate>
                <Divider />
                <CardHeader
                  title="Availability"
                  subheader="When to recieve notifications"
                  action={
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      className={classes.submitButton}
                      onClick={this.updateFormValues}
                      disabled={
                        this.state.buttonLoading ||
                        !this.state.firstName ||
                        !this.state.lastName
                      }
                    >
                      Save details
                      {this.state.buttonLoading && (
                        <CircularProgress
                          size={30}
                          className={classes.progess}
                        />
                      )}
                    </Button>
                  }
                />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              this.state.emailNotificationsActive || false
                            }
                            name="emailNotificationsActive"
                            onChange={this.handleCheck}
                            color="primary"
                          />
                        }
                        label="Enable Email Notifications"
                        labelPlacement="end"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              this.state.textNotificationsActive || false
                            }
                            name="textNotificationsActive"
                            onChange={this.handleCheck}
                            color="primary"
                          />
                        }
                        label="Enable Text Message Notifications"
                        labelPlacement="end"
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Divider />
                      <br />
                      <h4>Notification Active Hours</h4>
                    </Grid>

                    <Grid item md={2} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.sundayActive || false}
                            name="sundayActive"
                            onChange={this.handleCheck}
                            color="primary"
                          />
                        }
                        label="Sunday"
                        labelPlacement="end"
                      />
                    </Grid>

                    <Grid item md={5} xs={12}>
                      <TextField
                        id="sundayStart"
                        fullWidth
                        label="Start Time"
                        type="time"
                        name="sundayStart"
                        value={this.state.sundayStart || ''}
                        className={classes.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <TextField
                        id="sundayEnd"
                        fullWidth
                        label="End Time"
                        type="time"
                        name="sundayEnd"
                        value={this.state.sundayEnd || ''}
                        className={classes.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>

                    <Grid item md={2} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.mondayActive || false}
                            name="mondayActive"
                            onChange={this.handleCheck}
                            color="primary"
                          />
                        }
                        label="Monday"
                        labelPlacement="end"
                      />
                    </Grid>

                    <Grid item md={5} xs={12}>
                      <TextField
                        id="mondayStart"
                        fullWidth
                        label="Start Time"
                        type="time"
                        name="mondayStart"
                        value={this.state.mondayStart || ''}
                        className={classes.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <TextField
                        id="mondayEnd"
                        fullWidth
                        label="End Time"
                        type="time"
                        name="mondayEnd"
                        value={this.state.mondayEnd || ''}
                        className={classes.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>

                    <Grid item md={2} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.tuesdayActive || false}
                            name="tuesdayActive"
                            onChange={this.handleCheck}
                            color="primary"
                          />
                        }
                        label="Tuesday"
                        labelPlacement="end"
                      />
                    </Grid>

                    <Grid item md={5} xs={12}>
                      <TextField
                        id="tuesdayStart"
                        fullWidth
                        label="Start Time"
                        type="time"
                        name="tuesdayStart"
                        value={this.state.tuesdayStart || ''}
                        className={classes.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <TextField
                        id="tuesdayEnd"
                        fullWidth
                        label="End Time"
                        type="time"
                        name="tuesdayEnd"
                        value={this.state.tuesdayEnd || ''}
                        className={classes.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>

                    <Grid item md={2} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.wednesdayActive || false}
                            name="wednesdayActive"
                            onChange={this.handleCheck}
                            color="primary"
                          />
                        }
                        label="Wednesday"
                        labelPlacement="end"
                      />
                    </Grid>

                    <Grid item md={5} xs={12}>
                      <TextField
                        id="wednesdayStart"
                        fullWidth
                        label="Start Time"
                        type="time"
                        name="wednesdayStart"
                        value={this.state.wednesdayStart || ''}
                        className={classes.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <TextField
                        id="wednesdayEnd"
                        fullWidth
                        label="End Time"
                        type="time"
                        name="wednesdayEnd"
                        value={this.state.wednesdayEnd || ''}
                        className={classes.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>

                    <Grid item md={2} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.thursdayActive || false}
                            name="thursdayActive"
                            onChange={this.handleCheck}
                            color="primary"
                          />
                        }
                        label="Thursday"
                        labelPlacement="end"
                      />
                    </Grid>

                    <Grid item md={5} xs={12}>
                      <TextField
                        id="thursdayStart"
                        fullWidth
                        label="Start Time"
                        type="time"
                        name="thursdayStart"
                        value={this.state.thursdayStart || ''}
                        className={classes.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <TextField
                        id="thursdayEnd"
                        fullWidth
                        label="End Time"
                        type="time"
                        name="thursdayEnd"
                        value={this.state.thursdayEnd || ''}
                        className={classes.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>

                    <Grid item md={2} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.fridayActive || false}
                            name="fridayActive"
                            onChange={this.handleCheck}
                            color="primary"
                          />
                        }
                        label="Friday"
                        labelPlacement="end"
                      />
                    </Grid>

                    <Grid item md={5} xs={12}>
                      <TextField
                        id="fridayStart"
                        fullWidth
                        label="Start Time"
                        type="time"
                        name="fridayStart"
                        value={this.state.fridayStart || ''}
                        className={classes.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <TextField
                        id="fridayEnd"
                        fullWidth
                        label="End Time"
                        type="time"
                        name="fridayEnd"
                        value={this.state.fridayEnd || ''}
                        className={classes.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>

                    <Grid item md={2} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.saturdayActive || false}
                            name="saturdayActive"
                            onChange={this.handleCheck}
                            color="primary"
                          />
                        }
                        label="Saturday"
                        labelPlacement="end"
                      />
                    </Grid>

                    <Grid item md={5} xs={12}>
                      <TextField
                        id="saturdayStart"
                        fullWidth
                        label="Start Time"
                        type="time"
                        name="saturdayStart"
                        value={this.state.saturdayStart || ''}
                        className={classes.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <TextField
                        id="saturdayEnd"
                        fullWidth
                        label="End Time"
                        type="time"
                        name="saturdayEnd"
                        value={this.state.saturdayEnd || ''}
                        className={classes.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions />
              </form>
            </Card>
            <Footer></Footer>
          </main>
        </div>
      );
    }
  }
}

export default Store.withStore(withStyles(styles)(Home));
