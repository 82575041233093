import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

// import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Store from '../services/Store';
import axios from '../helpers/axios';
import QrCode from './scans/QrCode';
import Cleaner from './scans/Cleaner';
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CheckIcon from '@material-ui/icons/Check';
import HomeIcon from '@material-ui/icons/Home';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

import TeamSlug from './scans/TeamSlug';
const queryString = require('query-string');
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  speedDial: {
    position: 'absolute',
    zIndex: 99999,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  staticTooltipLabel: {
    whiteSpace: 'nowrap',
    maxWidth: 'none',
  },
  backDrop: {
    zIndex: 99990,
  },
});

class Scan extends Component {
  constructor(props) {
    super(props);
    const parsed = queryString.parse(location.search);
    //check if i have permission to view cleaner mode
    this.state = {
      reports: [],
      uiLoading: true,
      buttonLoading: false,
      open: false,
      hidden: false,
      preview: 'preview' in parsed || false,
      logIssue: 'log' in parsed || false,
      actions: [
        {
          icon: <PhotoCameraIcon />,
          name: 'Capture',
          onClick: function () {
            // var getUrl = window.location;
            // let url = getUrl.protocol + "//" + getUrl.host + "/c/"+params.id+"?preview";
            // window.href="http://google.com"
            window.location.replace(
              '/t/' + props.match.params.teamSlug + '?log',
            );
          },
        },
        {
          icon: <CheckIcon />,
          name: 'My Issues',
          onClick: function () {
            // var getUrl = window.location;
            // let url = getUrl.protocol + "//" + getUrl.host + "/c/"+params.id+"?preview";
            // window.href="http://google.com"
            window.location.replace('/t/' + props.match.params.teamSlug);
          },
        },
        {
          icon: <HomeIcon />,
          name: 'Home',
          onClick: function () {
            // var getUrl = window.location;
            // let url = getUrl.protocol + "//" + getUrl.host + "/c/"+params.id+"?preview";
            // window.href="http://google.com"
            window.location.replace('/');
          },
        },
      ],
    };
  }

  handleVisibility = () => {
    const { hidden } = this.state;
    this.setState({ hidden: !hidden });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { actions } = this.state;
    let content = <div>Invalid</div>;

    let toolbar = '';

    if (this.props.authenticated && this.props.match.params.teamSlug) {
      toolbar = (
        <Container style={{ padding: 0 }} maxWidth="sm">
          <div className={classes.root}>
            <Grid
              justifyContent="center"
              spacing={3}
              alignItems="center"
              container
            >
              <Grid item md={12} xs={12}>
                <Backdrop className={classes.backDrop} open={this.state.open} />
                <SpeedDial
                  ariaLabel="SpeedDial tooltip example"
                  className={classes.speedDial}
                  hidden={this.state.hidden}
                  icon={<SpeedDialIcon />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      classes={{
                        staticTooltipLabel: classes.staticTooltipLabel,
                      }}
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      tooltipOpen
                      onClick={action.onClick || this.handleClose}
                    />
                  ))}
                </SpeedDial>
              </Grid>
            </Grid>
          </div>
        </Container>
      );
    }

    if (
      this.props.match.params.qrCodeID &&
      (!this.props.authenticated || this.state.preview || this.state.logIssue)
    ) {
      content = (
        <div>
          <QrCode
            logIssue={this.props.authenticated && this.state.logIssue}
            qrCodeID={this.props.match.params.qrCodeID}
          />
        </div>
      );
    } else if (
      this.props.match.params.qrCodeSlug &&
      (!this.props.authenticated || this.state.preview || this.state.logIssue)
    ) {
      content = (
        <div>
          <QrCode
            logIssue={this.props.authenticated && this.state.logIssue}
            qrCodeSlug={this.props.match.params.qrCodeSlug}
          />
        </div>
      );
    } else if (this.props.match.params.qrCodeID && this.props.authenticated) {
      content = (
        <div>
          <Cleaner qrCodeID={this.props.match.params.qrCodeID} />
        </div>
      );
    } else if (this.props.match.params.qrCodeSlug && this.props.authenticated) {
      content = (
        <div>
          <Cleaner qrCodeSlug={this.props.match.params.qrCodeSlug} />
        </div>
      );
    } else if (
      this.props.match.params.teamSlug &&
      (!this.props.authenticated || this.state.preview || this.state.logIssue)
    ) {
      content = (
        <div>
          <TeamSlug
            toolbar={toolbar}
            logIssue={this.props.authenticated && this.state.logIssue}
            teamSlug={this.props.match.params.teamSlug}
          />
        </div>
      );
    } else if (this.props.match.params.teamSlug && this.props.authenticated) {
      content = (
        <div>
          <Cleaner
            toolbar={toolbar}
            teamSlug={this.props.match.params.teamSlug}
            qrCodeID="NONE"
          />
        </div>
      );
    }

    return content;
  }
}

export default Store.withStore(withStyles(styles)(Scan));
