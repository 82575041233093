import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
// import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Store from '../services/Store';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from '../helpers/axios';
import EditCode from './codes/EditCode';
import { DataGrid } from '@material-ui/data-grid';
import { auth } from '../services/firebase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import PhotoModal from '../components/PhotoModal';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Avatar from '@material-ui/core/Avatar';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ConfirmDialog from '../components/ConfirmDialog';
import FormControl from '@material-ui/core/FormControl';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Resizer from 'react-image-file-resizer';
import Assign from './reports/Assign';
import piexif from 'piexifjs';
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  IconButton,
  Divider,
  InputLabel,
  Select,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import Chart from 'chart.js/auto';
import { Doughnut, Bar, Line } from 'react-chartjs-2';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  saveRight: {
    marginLeft: 'auto',
    marginTop: '10px',
    float: 'right',
  },
  locationText: {
    paddingLeft: '15px',
  },
  buttonProperty: {
    position: 'absolute',
    top: '50%',
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    marginLeft: -75,
    top: '35%',
  },
  progess: {
    position: 'absolute',
  },
  uploadButton: {
    marginLeft: '8px',
    margin: theme.spacing(1),
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10,
  },
  submitButton: {
    marginTop: '10px',
  },
  paper: {
    position: 'absolute',
    width: '80%',
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

const columns = [
  { field: 'id', headerName: 'ID', width: 120 },
  {
    field: 'status',
    width: 300,
    headerName: 'Status',
    editable: false,
  },
  {
    field: 'url',
    headerName: 'Download',
    editable: false,
    width: 400,
    renderCell: (params) => {
      if (params.getValue(params.id, 'downloadURL').length > 0) {
        return (
          <a target="_blank" href={params.getValue(params.id, 'downloadURL')}>
            Download
          </a>
        );
      }
    },
  },
  {
    field: 'generatedAt',
    headerName: 'Generated At',
    width: 120,
    renderCell: (params) => {
      return timeConverter(params.getValue(params.id, 'createdAt'));
    },
  },
];
function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = addZero(a.getHours());
  var ampm = hour >= 12 ? 'pm' : 'am';
  hour = hour % 12;
  hour = hour ? hour : 12; // the hour '0' should be '12'
  var min = addZero(a.getMinutes());
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ampm;
  return time;
}
function addZero(i) {
  if (i < 10) {
    i = '0' + i;
  }
  return i;
}

function arrayUnique(array) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
}

class Dashboard extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    var sevenDaysAgo = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
    var dd7 = sevenDaysAgo.getDate();
    var mm7 = sevenDaysAgo.getMonth() + 1;
    var yyyy7 = sevenDaysAgo.getFullYear();
    if (dd7 < 10) {
      dd7 = '0' + dd7;
    }

    if (mm7 < 10) {
      mm7 = '0' + mm7;
    }
    this.state = {
      reports: [],
      dailyReports: [],
      exports: [],
      uiLoading: true,
      buttonLoading: false,
      mapCenter: { lat: 37.80204, lng: -122.421244 },
      mapZoom: 12,
      currentCoordinates: '',
      rubbishTypes: [],
      teamMembers: [],
      team: {},
      showReportModal: false,
      confirmOpen: false,
      confirmModalTitle: '',
      photoPreviewAfter: 'Attach an After Photo',
      filterStart: yyyy7 + '-' + mm7 + '-' + dd7,
      filterEnd: yyyy + '-' + mm + '-' + dd,
      filterStatus: 'any',
      filterUser: 'any',
      filterRubbishType: 'any',
      rubbishTypesFilter: [],
    };
  }
  createReport = () => {
    // console.log(auth());
    auth().currentUser.getIdToken(true);
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    const formRequest = {};
    axios
      .post(`/api/team/${currentTeam}/pdf_report`, formRequest)
      .then((teamResponse) => {
        //add team to tam list and set it as current team
        this.setState({ buttonLoading: false });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.props.history.push('/login');
        }
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
  };
  setReports = (team, authToken) => {
    const {
      filterStart,
      filterEnd,
      filterStatus,
      filterUser,
      filterRubbishType,
    } = this.state;
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(`/api/team/${team}/reports`, {
        params: {
          filterStatus: filterStatus,
          filterStart: filterStart,
          filterEnd: filterEnd,
          filterUser: filterUser,
          filterRubbishType: filterRubbishType,
        },
      })
      .then((response) => {
        let reports = {};
        response.data.forEach((rep) => {
          if (rep.lat && rep.long) {
            // console.log(rep);
            let coordkey =
              parseFloat(rep.lat).toFixed(4) +
              '_' +
              parseFloat(rep.long).toFixed(4);
            if (reports[coordkey] == undefined) {
              reports[coordkey] = [];
            }
            reports[coordkey].push(rep);
          }
        });
        this.setState({
          reports: reports,
          uiLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMsg: 'Error in retrieving the data' });
      });
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(`/api/dashboard/${team}`, {
        params: {
          filterStatus: filterStatus,
          filterStart: filterStart,
          filterEnd: filterEnd,
          filterUser: filterUser,
          filterRubbishType: filterRubbishType,
        },
      })
      .then((response) => {
        this.setState({ dailyReports: response.data.stats });
        let labels = [];

        if (response.data.stats && response.data.stats['rubbishTypes']) {
          Object.keys(response.data.stats['rubbishTypes']).forEach((key) => {
            labels.push(key);
          });
          this.setRubbishTypeFilter(labels);
        }

        // console.log(response.data.stats);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMsg: 'Error in retrieving the data' });
      });
  };
  setExports = (team, authToken) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(`/api/team/${team}/pdf_reports`)
      .then((response) => {
        this.setState({
          reports: response.data,
          uiLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMsg: 'Error in retrieving the data' });
      });
  };
  resolveReport = (reportId, coordkey) => {
    const { store, qrCodeID } = this.props;
    const { reports } = this.state;
    // console.log(qrCodeID);

    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    axios.defaults.headers.common = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${authToken}`,
    };

    var formData = new FormData();

    formData.append('reportIDs', [reportId]);

    if (this.state.photoStringAfter) {
      formData.append('rubbishAfterPhoto', this.state.photoStringAfter);
    }
    this.setState({ showQuickLoad: true });
    window.this = this;
    axios
      .post(`/api/code/${qrCodeID}/resolve_reports`, formData)
      .then((teamResponse) => {
        let newReports = reports;
        newReports[coordkey] = reports[coordkey].filter(
          (item) => item.id !== reportId,
        );
        window.this.setState({
          reports: newReports,
          photoStringAfter: '',
          showReportModal: false,
          photoPreviewAfter: 'Attach an After Photo',
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
  };
  triggerPhotoAfter = (e) => {
    e.preventDefault();
    document.getElementById('rubbishAfterPhoto').click();
  };
  showPhotoPreviewAfter = (e) => {
    const { photoPreviewAfter } = this.state;
    const file = document.querySelector('#rubbishAfterPhoto').files[0];
    if (file) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          800,
          800,
          'JPEG',
          100,
          0,
          (uri) => {
            let photoPreviewAfter = <img className="photo-preview" src={uri} />;
            this.setState({ photoPreviewAfter: photoPreviewAfter });
          },
          'base64',
          200,
          200,
        );
        Resizer.imageFileResizer(
          e.target.files[0],
          800,
          800,
          'JPEG',
          100,
          0,
          (uri) => {
            var reader = new FileReader();
            // reader.setState = this.setState;
            //read exif from original file, and apply to newly resized file
            try {
              reader.readAsDataURL(e.target.files[0]);
              reader.onload = () => {
                var reader2 = new FileReader();
                reader2.readAsDataURL(uri);
                reader2.onload = () => {
                  try {
                    var exifObj = piexif.load(reader.result);
                    if (exifObj['0th'][274] === 6) {
                      //idk but this fixes the wierd rotations, 6 tells the computer to rotate it, 0 says dont
                      exifObj['0th'][274] = 0;
                    }
                    var exifStr = piexif.dump(exifObj);

                    let newFile = piexif.insert(exifStr, reader2.result);
                    var exifObj2 = piexif.load(newFile);
                    this.setState({
                      photoStringAfter: dataURLtoFile(newFile, 'image-upload'),
                    });
                  } catch (err) {
                    console.log(err);
                    this.setState({
                      photoStringAfter: uri,
                    });
                  }
                };
                reader2.onerror = function (error) {
                  console.log('Error: ', error);
                };
              };
              reader.onerror = function (error) {
                console.log('Error: ', error);
              };
            } catch (err) {
              console.log(err);
              this.setState({
                photoStringAfter: uri,
              });
            }
          },
          'file',
          200,
          200,
        );
      } catch (err) {
        console.log(err);
        this.setState({
          photoPreviewAfter: 'Error Attaching Photo',
          photoStringAfter: null,
        });
      }
    }
  };
  componentWillMount = () => {
    this._isMounted = true;
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    this.setReports(currentTeam, authToken);
    this.setExports(currentTeam, authToken);
    this.setTeam(currentTeam, store.get('user'), authToken);
    // console.log(store.get('user').myTeams[currentTeam]);
    // console.log(store.get('user').myTeams[currentTeam].mapCenter);
    if (
      store.get('user') &&
      store.get('user').myTeams[currentTeam] &&
      store.get('user').myTeams[currentTeam].mapCenter
    ) {
      // console.log('set center');
      this.setState({
        mapCenter: store.get('user').myTeams[currentTeam].mapCenter,
      });
    } else {
      this.setState({ mapCenter: { lat: 37.80204, lng: -122.421244 } });
    }
    store.on('currentTeam').subscribe((currentTeam) => {
      if (this._isMounted) {
        this.setState({ uiLoading: true });
        if (
          store.get('user') &&
          store.get('user').myTeams[currentTeam] &&
          store.get('user').myTeams[currentTeam].mapCenter
        ) {
          this.setState({
            mapCenter: store.get('user').myTeams[currentTeam].mapCenter,
          });
        } else {
          this.setState({ mapCenter: { lat: 37.80204, lng: -122.421244 } });
        }
        this.setReports(currentTeam, authToken);
        this.setExports(currentTeam, authToken);
      }
    });
  };

  filterResults = () => {
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    this.setReports(currentTeam, authToken);
  };
  componentWillUnmount() {
    if (this._isMounted) this._isMounted = false;
  }
  timeConverter = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = this.addZero(a.getHours());
    var ampm = hour >= 12 ? 'pm' : 'am';
    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'
    var min = this.addZero(a.getMinutes());
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ampm;
    return time;
  };
  addZero = (i) => {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  };
  onSelectType = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  displayReports(key) {
    this.setState({
      showReportModal: true,
      currentCoordinates: key,
    });
  }
  handleClose = () => {
    this.setState({
      showReportModal: false,
      currentCoordinates: '',
    });
  };
  setConfirmOpen = (setConfirm = false) => {
    this.setState({
      confirmOpen: setConfirm,
      confirmModalTitle: '',
      confirmationFunction: undefined,
    });
  };

  setTeam = (team, user, authToken) => {
    if (user.moderator.includes(team)) {
      this.setState({ isModerator: true });
    } else {
      this.setState({ isModerator: false });
    }
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(`/api/team/${team}`)
      .then((response) => {
        this.setState({
          teamMembers: response.data.teamMembers || [],
          rubbishTypes: response.data.rubbishTypes || [],
          schemeTypes: response.data.schemeTypes || [],
          rubbishTeamTypeSchemeID: response.data.rubbishTeamTypeSchemeID,
          rubbishQrTypesID: response.data.rubbishQrTypesID,
          teamDisplayName: response.data.teamDisplayName,
          teamSlugID: response.data.teamSlugID,
          uiLoading: false,
        });
        let filterRubbishType = [];
        if (response.data.schemeTypes) {
          Object.keys(response.data.schemeTypes).forEach((key) => {
            if (response.data.schemeTypes[key].rubbishTypeIDs.count > 0) {
              response.data.schemeTypes[key].rubbishTypeIDs.forEach((type) => {
                if (this.state.rubbishTypes[key]) {
                  filterRubbishType.push(this.state.rubbishTypes[key].title);
                }
              });
            }
          });
        }
        if (Object.keys(this.state.rubbishTypes).length > 0) {
          Object.keys(this.state.rubbishTypes).forEach((key) => {
            if (this.state.rubbishTypes[key].count > 0) {
              filterRubbishType.push(this.state.rubbishTypes[key].title);
            }
          });
        }
        this.setRubbishTypeFilter(filterRubbishType);
        // console.log(response.data.teamMembers);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMsg: 'Error in retrieving the data' });
      });
  };

  reportModal() {
    const { classes, store } = this.props;
    const { currentCoordinates, showReportModal, reports } = this.state;
    let modalStyle = {
      top: `45%`,
      left: `50%`,
      transform: `translate(-45%, -50%)`,
    };

    if (reports[currentCoordinates]) {
      return (
        <Modal
          open={showReportModal}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <Grid item md={12} xs={12}>
              <List>
                {reports[currentCoordinates].map((rep) => {
                  let rubbishPhoto = <DeleteOutlineIcon />;
                  if (rep.rubbishPhotoURL) {
                    rubbishPhoto = (
                      <PhotoModal rubbishPhotoURL={rep.rubbishPhotoURL} />
                    );
                  }
                  let secondRow =
                    'Reported at ' + this.timeConverter(rep.reportedTimeStamp);
                  let resolveButton = '';
                  if (rep.isResolved == true) {
                    resolveButton = 'Resolved';
                  } else {
                    resolveButton = (
                      <>
                        <IconButton
                          onClick={() => {
                            this.setState({
                              confirmationFunction: () => {
                                this.resolveReport(rep.id, currentCoordinates);
                              },
                              confirmModalTitle: 'Mark Report as Resolved?',
                              confirmOpen: true,
                            });
                          }}
                          edge="end"
                          aria-label="delete"
                        >
                          <DoneOutlineIcon />
                        </IconButton>

                        <Assign reportID={rep.id} />
                      </>
                    );
                  }
                  return (
                    <div key={rep.storyID}>
                      <ListItem key={rep.storyID}>
                        <ListItemAvatar>
                          <Avatar variant="rounded" className={classes.rounded}>
                            {rubbishPhoto}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={rep.rubbishType + ': ' + rep.comments}
                          secondary={secondRow}
                        />
                        <ListItemSecondaryAction>
                          {resolveButton}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </div>
                  );
                })}
              </List>
            </Grid>
          </div>
        </Modal>
      );
    }
  }
  setRubbishTypeFilter = (newTypes) => {
    const { rubbishTypesFilter } = this.state;

    let updateTypes = arrayUnique(rubbishTypesFilter.concat(newTypes));
    this.setState({ rubbishTypesFilter: updateTypes });
  };
  dailyIssuesChart() {
    const { dailyReports, filterStart } = this.state;
    const options = {
      indexAxis: 'x',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: true,
        },
        title: {
          display: false,
          text: 'Daily Issues',
        },
      },
    };
    let labels = [];
    let openReports = [];
    let resolvedReports = [];
    let loggedReports = [];
    let startParse = new Date(filterStart);
    // labels.push(
    //   (startParse.getMonth() + 1).toString() +
    //     '/' +
    //     startParse.getDate().toString() +
    //     '/' +
    //     startParse.getFullYear().toString(),
    // );
    labels.push('');
    openReports.push(0);
    resolvedReports.push(0);
    loggedReports.push(0);
    if (dailyReports && dailyReports['dailyCount']) {
      Object.keys(dailyReports['dailyCount']).forEach((key) => {
        labels.push(key);
        openReports.push(dailyReports['dailyCount'][key]['open']);
        resolvedReports.push(dailyReports['dailyCount'][key]['resolved']);
        loggedReports.push(dailyReports['dailyCount'][key]['logged']);
      });
    }
    labels.push('');
    openReports.push(0);
    resolvedReports.push(0);
    loggedReports.push(0);
    const data = {
      labels,
      datasets: [
        {
          label: 'Reported',
          data: openReports,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Resolved',
          data: resolvedReports,
          borderColor: 'rgb(60,179,113)',
          backgroundColor: 'rgba(60,179,113, 0.5)',
        },
        {
          label: 'Logged',
          data: loggedReports,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };
    return (
      <>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              Daily Issues
            </Typography>
            <div style={{ minHeight: '250px' }}>
              <Line data={data} options={options} />
            </div>
          </CardContent>
        </Card>
      </>
    );
  }

  rubbishTypesChart() {
    const { dailyReports } = this.state;

    let labels = [];
    let typeCount = [];
    let resolvedReports = [];
    let maxCount = 0;
    if (dailyReports && dailyReports['rubbishTypes']) {
      Object.keys(dailyReports['rubbishTypes']).forEach((key) => {
        labels.push(key);
        typeCount.push(dailyReports['rubbishTypes'][key]);
        if (dailyReports['rubbishTypes'][key] > maxCount) {
          maxCount = Number(dailyReports['rubbishTypes'][key]);
        }
      });
    }

    const data = {
      labels,
      datasets: [
        {
          data: typeCount,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    };

    const options = {
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 1,
        },
      },
      barThickness: 15,
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: 'Issues By Rubbish Type',
        },
      },
      scales: {
        x: {
          max: Math.ceil((maxCount * 16) / 15),
        },
      },
    };
    return (
      <>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              Issues By Rubbish Type
            </Typography>
            <div>
              <div style={{ height: (labels.length + 1) * 30 + 'px' }}>
                <Bar data={data} options={options} />
              </div>
            </div>
          </CardContent>
        </Card>
      </>
    );
  }
  totalIssuesChart() {
    const { dailyReports } = this.state;
    return (
      <>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              Total Issues
            </Typography>
            <Typography variant="h2" component="h2">
              {dailyReports['totalCount'] || 0}
            </Typography>
          </CardContent>
        </Card>
      </>
    );
  }
  uniqueUsersChart() {
    const { dailyReports } = this.state;
    let userCount = dailyReports['userIds']
      ? dailyReports['userIds'].length
      : 0;
    return (
      <>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              Unique User
            </Typography>
            <Typography variant="h2" component="h2">
              {userCount}
            </Typography>
          </CardContent>
        </Card>
      </>
    );
  }
  uniqueStreetsChart() {
    const { dailyReports } = this.state;
    let streetCount = dailyReports['streets']
      ? dailyReports['streets'].length
      : 0;
    return (
      <>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              Unique Streets
            </Typography>
            <Typography variant="h2" component="h2">
              {streetCount}
            </Typography>
          </CardContent>
        </Card>
      </>
    );
  }
  runtimeChart() {
    const { dailyReports } = this.state;

    return (
      <>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              Total Run Time
            </Typography>
            <Typography variant="h2" component="h2">
              {dailyReports['runTime'] | 0}
            </Typography>
          </CardContent>
        </Card>
      </>
    );
  }
  filterDash() {
    const { classes } = this.props;
    const { dailyReports, teamMembers } = this.state;

    let labels = [];

    if (dailyReports && dailyReports['rubbishTypes']) {
      Object.keys(dailyReports['rubbishTypes']).forEach((key) => {
        labels.push(key);
      });
    }
    return (
      <>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h5">
              Filter
            </Typography>
            <Grid container spacing={1}>
              <Grid item sm={3} xs={6}>
                <InputLabel id="startDateRubbishTypeLabel">
                  Start Date
                </InputLabel>
                <TextField
                  fullWidth
                  id="startDate"
                  type="date"
                  name="filterStart"
                  defaultValue={this.state.filterStart}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item sm={3} xs={6}>
                <InputLabel id="endDateRubbishTypeLabel">End Date</InputLabel>
                <TextField
                  fullWidth
                  id="endDate"
                  type="date"
                  name="filterEnd"
                  defaultValue={this.state.filterEnd}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <InputLabel id="newSelectRubbishTypeLabel">Status</InputLabel>
                <Select
                  fullWidth
                  value={this.state.filterStatus}
                  name="filterStatus"
                  label="Status"
                  id="filterStatus"
                  onChange={this.onSelectType}
                >
                  <MenuItem key={'any'} value={'any'}>
                    Any
                  </MenuItem>
                  <MenuItem key={'resolved'} value={'Resolved'}>
                    Resolved
                  </MenuItem>
                  <MenuItem key={'reported'} value={'Reported'}>
                    Reported
                  </MenuItem>
                  <MenuItem key={'logged'} value={'Logged'}>
                    Logged
                  </MenuItem>
                </Select>
              </Grid>
              {/* <Grid item sm={3} xs={12}>
                <InputLabel id="newSelectRubbishTypeLabel">User</InputLabel>
                <Select
                  fullWidth
                  value={this.state.filterUser}
                  name="filterUser"
                  label="User"
                  id="filterUser"
                  onChange={this.onSelectType}
                >
                  <MenuItem key={'any'} value={'any'}>
                    Any
                  </MenuItem>
                  {teamMembers.map((teamMember) => {
                    const s =
                      teamMember['firstName'] + ' ' + teamMember['lastName'];
                    return (
                      <MenuItem
                        key={teamMember['userID']}
                        value={teamMember['userID']}
                      >
                        {s}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid> */}
              <Grid item sm={3} xs={12}>
                <InputLabel id="newSelectRubbishTypeLabel">
                  Rubbish Type
                </InputLabel>
                <Select
                  fullWidth
                  value={this.state.filterRubbishType}
                  name="filterRubbishType"
                  label="Rubbish Type"
                  id="filterRubbishType"
                  onChange={this.onSelectType}
                >
                  <MenuItem key={'any'} value={'any'}>
                    Any
                  </MenuItem>
                  {this.state.rubbishTypesFilter.map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.saveRight}
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={this.filterResults}
                >
                  Filter Results
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  }
  render() {
    const { classes } = this.props;
    const { exports, reports, dailyReports } = this.state;
    if (this.state.uiLoading === true) {
      return (
        <div className={classes.root}>
          <Header></Header>

          <main className={classes.content}>
            <div className={classes.toolbar} />
            {this.state.uiLoading && (
              <CircularProgress size={150} className={classes.uiProgess} />
            )}
          </main>
        </div>
      );
    } else {
      let coordUsed = [];
      return (
        <div className={classes.root}>
          <Header></Header>
          <main className={classes.content}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {this.filterDash()}
              </Grid>
              <Grid item xs={12}>
                {this.dailyIssuesChart()}
              </Grid>
              <Grid item xs={4}>
                {this.totalIssuesChart()}
              </Grid>
              <Grid item xs={4}>
                {this.uniqueUsersChart()}
              </Grid>
              <Grid item xs={4}>
                {this.uniqueStreetsChart()}
              </Grid>
              {/* <Grid item xs={4}>
                {this.runtimeChart()}
              </Grid> */}
              <Grid item xs={12}>
                {this.rubbishTypesChart()}
              </Grid>
              {/* <Grid item xs={12}>
                <LoadScript googleMapsApiKey="AIzaSyDPxht5Pz1aXxzuT5Gieomx6e1O6zOxR4s">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={this.state.mapCenter}
                    zoom={this.state.mapZoom}
                  >
                    {Object.keys(reports).map((key) => {
                      let rep = reports[key][0];
                      if (rep) {
                        return (
                          <Marker
                            key={key}
                            onClick={() => this.displayReports(key)}
                            position={{
                              lat: parseFloat(rep.lat),
                              lng: parseFloat(rep.long),
                            }}
                          />
                        );
                      }
                    })}
                  </GoogleMap>
                </LoadScript>
              </Grid> */}
            </Grid>
            <div className={classes.toolbar} />
            <ConfirmDialog
              title={this.state.confirmModalTitle}
              open={this.state.confirmOpen}
              setOpen={this.setConfirmOpen}
              onConfirm={() => this.state.confirmationFunction()}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <Button
                  className={classes.button}
                  variant="outlined"
                  size="large"
                  endIcon={<CameraAltIcon />}
                  onClick={this.triggerPhotoAfter}
                >
                  {this.state.photoPreviewAfter}
                </Button>
                <input
                  onChange={this.showPhotoPreviewAfter}
                  id="rubbishAfterPhoto"
                  name="rubbishAfterPhoto"
                  type="file"
                  accept="image/*"
                />
              </FormControl>
            </ConfirmDialog>
            {/* <DataGrid
              rows={exports}
              columns={columns}
              pageSize={25}
              style={{ height: 400, width: '100%' }}
            /> */}
            {this.reportModal()}
            <Footer></Footer>
          </main>
        </div>
      );
    }
  }
}

export default Store.withStore(withStyles(styles)(Dashboard));
