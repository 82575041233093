import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Alert from '@mui/material/Alert';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {
  signin,
  signInWithGoogle,
  signInWithFacebook,
  signInWithApple,
  resetPassword,
  sendSignInLink,
} from '../helpers/auth';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormGroup,
} from '@material-ui/core';
import Store from '../services/Store';
import CircularProgress from '@material-ui/core/CircularProgress';
import { auth } from '../services/firebase';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Rubbish.love
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = (theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/images/background.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imageTag: {
    margin: 15,
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Login extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      loading: false,
      email: '',
      password: '',
      showResetPassword: false,
      resetEmail: '',
      errors: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.googleSignIn = this.googleSignIn.bind(this);
    this.facebookSignIn = this.facebookSignIn.bind(this);
    this.appleSignIn = this.appleSignIn.bind(this);
    this.sendResetPassword = this.sendResetPassword.bind(this);
    this.sendSignInLinkToEmail = this.sendSignInLinkToEmail.bind(this);

    // this.githubSignIn = this.githubSignIn.bind(this);
  }
  componentWillMount = () => {
    //check for login token
    // Confirm the link is a sign-in with email link.
    if (auth().isSignInWithEmailLink(window.location.href)) {
      this.isloading(true);
      // Additional state parameters can also be passed via URL.
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const email = urlParams.get('email').replace(' ', '+');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      auth()
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  openResetPassword = () => {
    this.setState({ showResetPassword: true });
  };
  handleClose = () => {
    this.setState({ showResetPassword: false });
  };
  isloading = (is_loading) => {
    this.setState({ loading: is_loading });
  };
  async sendSignInLinkToEmail(event) {
    event.preventDefault();
    this.isloading(true);
    await sendSignInLink(this.state.email)
      .then(() => {
        this.setState({ error: 'Email Sent' });
        this.isloading(false);
      })
      .catch((error) => {
        this.setState({ error: error.message });
        this.isloading(false);
      });
  }
  async handleSubmit(event) {
    event.preventDefault();
    window.localStorage.setItem('currentTeam', '0');
    const { store } = this.props;
    store.set('currentTeam')('0');
    this.isloading(true);
    await signin(this.state.email, this.state.password).catch((error) => {
      this.setState({ error: error.message });
      this.isloading(false);
    });
  }
  async googleSignIn() {
    window.localStorage.setItem('currentTeam', '0');
    const { store } = this.props;
    store.set('currentTeam')('0');
    this.isloading(true);
    await signInWithGoogle().catch((error) => {
      this.setState({ error: error.message });
      this.isloading(false);
    });
  }
  async facebookSignIn() {
    window.localStorage.setItem('currentTeam', '0');
    const { store } = this.props;
    store.set('currentTeam')('0');
    this.isloading(true);
    await signInWithFacebook().catch((error) => {
      this.setState({ error: error.message });
      this.isloading(false);
    });
  }
  async appleSignIn() {
    window.localStorage.setItem('currentTeam', '0');
    const { store } = this.props;
    store.set('currentTeam')('0');
    this.isloading(true);
    await signInWithApple().catch((error) => {
      this.setState({ error: error.message });
      this.isloading(false);
    });
  }

  async sendResetPassword() {
    try {
      await resetPassword(this.state.resetEmail);
    } catch (error) {}
    this.setState({
      error: 'Password Reset Sent',
      resetEmail: '',
      showResetPassword: false,
    });
  }

  render() {
    const { classes } = this.props;
    let alertError = '';
    if (this.state.error) {
      alertError = (
        <Alert variant="outlined" severity="error">
          {this.state.error}
        </Alert>
      );
    }
    return (
      <>
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image}>
            <img className={classes.imageTag} src="/images/logo.png" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              {alertError}
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={this.handleChange}
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={this.handleChange}
                  autoComplete="current-password"
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />

                {this.state.loading ? (
                  <Grid container>
                    <Grid>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={6}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={this.handleSubmit}
                          className={classes.submit}
                        >
                          Sign In
                        </Button>
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={this.sendSignInLinkToEmail}
                          className={classes.submit}
                        >
                          Email Sign In Link
                        </Button>
                      </Grid>
                    </Grid>
                    <Button
                      variant="outlined"
                      type="button"
                      fullWidth
                      className="mb-2"
                      onClick={this.googleSignIn}
                    >
                      Sign in with Google
                    </Button>
                    <Button
                      variant="outlined"
                      type="button"
                      fullWidth
                      style={{ backgroundColor: '#3b5998', color: '#ffffff' }}
                      className="mb-2"
                      onClick={this.facebookSignIn}
                    >
                      Sign in with Facebook
                    </Button>
                    <Button
                      variant="outlined"
                      type="button"
                      fullWidth
                      style={{ backgroundColor: '#000000', color: '#ffffff' }}
                      className="mb-2"
                      onClick={this.appleSignIn}
                    >
                      Sign in with Apple
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        <Link
                          href="#"
                          onClick={this.openResetPassword}
                          variant="body2"
                        >
                          Forgot password?
                        </Link>
                      </Grid>
                      <Grid item></Grid>
                    </Grid>
                  </>
                )}

                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </div>
          </Grid>
        </Grid>

        <Dialog
          fullWidth
          maxWidth="md"
          open={this.state.showResetPassword}
          onClose={this.handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            <h2 id="simple-modal-title">Reset Password</h2>
            <FormControl fullWidth component="fieldset">
              <FormGroup aria-label="position" row>
                <TextField
                  fullWidth
                  label="Email Address"
                  margin="dense"
                  name="resetEmail"
                  variant="outlined"
                  value={this.state.resetEmail}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" type="submit" onClick={this.handleClose}>
              Close
            </Button>

            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={this.sendResetPassword}
            >
              Reset Password
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default Store.withStore(withStyles(styles)(Login));
