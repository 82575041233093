import React, { Component } from 'react';
import {
  Button,
  Grid,
  TextField,
  IconButton,
  Divider,
  Dialog,
  InputLabel,
  Select,
  MenuItem,
  DialogContent,
  DialogActions,
  ListItemSecondaryAction,
} from '@material-ui/core';
import Store from '../../services/Store';
import axios from '../../helpers/axios';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Modal from '@material-ui/core/Modal';
import withStyles from '@material-ui/core/styles/withStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MouseOverPopover from '../../components/MouseOverPopover';
import MessageIcon from '@material-ui/icons/Message';
import EmailIcon from '@material-ui/icons/Email';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = addZero(a.getHours());
  var ampm = hour >= 12 ? 'pm' : 'am';
  hour = hour % 12;
  hour = hour ? hour : 12; // the hour '0' should be '12'
  var min = addZero(a.getMinutes());
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ampm;
  return time;
}
function addZero(i) {
  if (i < 10) {
    i = '0' + i;
  }
  return i;
}
const styles = (theme) => ({
  buttonProperty: {
    position: 'absolute',
    top: '50%',
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%',
  },
  progess: {
    position: 'absolute',
  },
  uploadButton: {
    marginLeft: '8px',
    margin: theme.spacing(1),
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10,
  },
  saveRight: {
    marginLeft: 'auto',
    marginTop: '10px',
    float: 'right',
  },
  gridContainer: {
    flexGrow: 1,
  },
  paper: {
    position: 'absolute',
    width: '60%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

class Assign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      report: [],
      notificationConfirmation: '',
      allMembers: [],
      assignedTo: [],
      newSubscriberID: '',
      teamMembers: [],
    };
  }

  handleOpen = () => {
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');

    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(`/api/team/${currentTeam}/reports/${this.props.reportID}/assigned`)
      .then((response) => {
        let memberLookup = {};
        response.data.teamMembers.forEach((mem) => {
          memberLookup[mem.memberID] = mem.firstName + ' ' + mem.lastName;
        });
        let assignedTo = response.data.report.assignedTo || [];
        let teamMembers = response.data.teamMembers.filter(
          (mem) => !assignedTo.includes(mem.memberID),
        );
        this.setState({
          modalOpen: true,
          allMembers: response.data.teamMembers,
          report: response.data.report,
          assignedTo: assignedTo || [],
          teamMembers: teamMembers || [],
          notificationConfirmation: '',
          memberLookup: memberLookup,
          uiLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMsg: 'Error in retrieving the data' });
      });
  };
  updateFormValues = () => {
    //save details
    // console.log('wtf');
    const { store } = this.props;
    const { newAccount } = this.state;
    const authToken = store.get('user').accessToken;
    const team = store.get('currentTeam');
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };

    const formRequest = {
      user: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      },
    };

    axios
      .post(`/api/team/${team}/users/${this.props.userID}`, formRequest)
      .then((teamResponse) => {
        this.props.setToast('Member Updated');
      })
      .catch((error) => {
        // if (error..status === 403) {
        //   this.props.history.push('/login');
        // }
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
  };
  handleClose = () => {
    this.setState({ modalOpen: false });
  };
  handleToastClose = () => {
    this.setState({ toastOpen: false });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleCheck = (event) => {
    if (event.target.checked) {
      this.setState({ [event.target.name]: true });
    } else {
      this.setState({ [event.target.name]: false });
    }
  };
  onSelectType = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  addAssignedTo = () => {
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const team = store.get('currentTeam');

    const { newSubscriberID, assignedTo, allMembers } = this.state;
    if (newSubscriberID.length > 0) {
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      let formRequest = {
        teamMemberID: newSubscriberID,
      };
      window.this = this;
      axios
        .post(
          `/api/team/${team}/reports/${this.props.reportID}/add_assigned`,
          formRequest,
        )
        .then((response) => {
          let newAssignedTo = assignedTo;
          newAssignedTo.push(newSubscriberID);
          let newTeamMembers = allMembers.filter(
            (mem) => !newAssignedTo.includes(mem.memberID),
          );
          window.this.setState({
            assignedTo: newAssignedTo,
            newSubscriberID: '',
            teamMembers: newTeamMembers,
          });
        })
        .catch((error) => {
          console.log(error);
          window.this.setState({
            buttonLoading: false,
          });
        });
    }
  };
  removeAssignedTo = (memberID, memberName) => {
    const { assignedTo, allMembers } = this.state;
    if (confirm('Are you sure you would like to remove ' + memberName + '?')) {
      const { store } = this.props;
      const { code } = this.state;
      const team = store.get('currentTeam');
      const authToken = store.get('user').accessToken;
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      axios
        .delete(
          `/api/team/${team}/reports/${this.props.reportID}/remove_assigned/${memberID}`,
        )
        .then((teamResponse) => {
          let newAssignedTo = assignedTo.filter((mem) => mem !== memberID);
          let newTeamMembers = allMembers.filter(
            (mem) => !newAssignedTo.includes(mem.memberID),
          );
          this.setState({
            assignedTo: newAssignedTo,
            teamMembers: newTeamMembers,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            buttonLoading: false,
          });
        });
    }
  };
  sendNotification = (memberID, memberName, notificationType) => {
    const { assignedTo, allMembers } = this.state;
    if (
      confirm(
        'Are you sure you would like to ' +
          notificationType +
          ' ' +
          memberName +
          ' right now?',
      )
    ) {
      const { store } = this.props;
      const { code } = this.state;
      const team = store.get('currentTeam');
      const authToken = store.get('user').accessToken;
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      this.setState({
        notificationConfirmation: <CircularProgress size={150} />,
      });
      const formRequest = {
        memberID: memberID,
        notificationType,
        notificationType,
      };
      axios
        .post(
          `/api/team/${team}/reports/${this.props.reportID}/send_notification`,
          formRequest,
        )
        .then((response) => {
          this.setState({
            notificationConfirmation: 'Notification has been queued',
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            buttonLoading: false,
            notificationConfirmation: 'Error',
          });
        });
    }
  };
  render() {
    const { userID, classes } = this.props;
    const { modalOpen } = this.state;

    let content = '';
    if (this.state.notificationConfirmation.length > 0) {
      content = (
        <>
          <h4>{this.state.notificationConfirmation}</h4>
        </>
      );
    } else {
      content = (
        <>
          <h2 id="simple-modal-title">Assigned Member</h2>

          <Grid container>
            <Grid item md={10} xs={10}>
              <InputLabel id="newTeamMemberIDLabel">
                Add Subscriber...
              </InputLabel>
              <Select
                fullWidth
                value={this.state.newSubscriberID || ''}
                name="newSubscriberID"
                label="Add Team Member..."
                id="newSubscriberID"
                onChange={this.onSelectType}
              >
                <MenuItem key={0} value="">
                  Select Member...
                </MenuItem>
                {this.state.teamMembers.map((key) => {
                  return (
                    <MenuItem key={key.memberID} value={key.memberID}>
                      {key.firstName} {key.lastName}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item md={2} xs={2}>
              <IconButton onClick={this.addAssignedTo} aria-label="settings">
                <AddCircleIcon fontSize="large" color="primary" />
              </IconButton>
            </Grid>
            <Grid item md={12} xs={12}>
              <List>
                {this.state.assignedTo.map((key) => {
                  if (this.state.memberLookup[key]) {
                    let memberName = this.state.memberLookup[key];
                    let emailNotificaitons = 'Not notified';
                    if (
                      this.state.report.notifiedIDS &&
                      this.state.report.notifiedIDS[key] &&
                      this.state.report.notifiedIDS[key]['email']
                    ) {
                      emailNotificaitons = this.state.report.notifiedIDS[key][
                        'email'
                      ]
                        .map((t) => {
                          return timeConverter(t);
                        })
                        .join(', ');
                    }
                    let textNotificaitons = 'Not notified';
                    if (
                      this.state.report.notifiedIDS &&
                      this.state.report.notifiedIDS[key] &&
                      this.state.report.notifiedIDS[key]['text']
                    ) {
                      textNotificaitons = this.state.report.notifiedIDS[key][
                        'text'
                      ]
                        .map((t) => {
                          return timeConverter(t);
                        })
                        .join(', ');
                    }
                    return (
                      <ListItem key={key} alignItems="flex-start">
                        <ListItemText
                          primary={
                            <MouseOverPopover
                              popoverElement={memberName}
                              popoverText={
                                <>
                                  <strong>Notified:</strong>
                                  <br />
                                  Email: {emailNotificaitons}
                                  <br />
                                  Text: {textNotificaitons}
                                </>
                              }
                            />
                          }
                        />
                        <ListItemSecondaryAction>
                          <Tooltip
                            title="Send Text Notification Now"
                            aria-label="text"
                          >
                            <IconButton
                              onClick={() =>
                                this.sendNotification(key, memberName, 'text')
                              }
                            >
                              <MessageIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title="Send Email Notification Now"
                            aria-label="email"
                          >
                            <IconButton
                              onClick={() =>
                                this.sendNotification(key, memberName, 'email')
                              }
                            >
                              <EmailIcon />
                            </IconButton>
                          </Tooltip>
                          <IconButton
                            onClick={() =>
                              this.removeAssignedTo(key, memberName)
                            }
                            edge="end"
                            aria-label="delete"
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  }
                })}
              </List>
            </Grid>
          </Grid>
        </>
      );
    }

    let modalStyle = {
      top: `45%`,
      left: `50%`,
      transform: `translate(-45%, -50%)`,
    };
    return (
      <>
        <Tooltip title="Assign to Team Member">
          <IconButton onClick={() => this.handleOpen()} edge="end">
            <PersonAddIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={modalOpen}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DialogContent>{content}</DialogContent>
          <DialogActions>
            <Button variant="outlined" type="submit" onClick={this.handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default Store.withStore(withStyles(styles)(Assign));
